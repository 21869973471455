const Communicate = () => {
  return (
    <div className="wrapper bg-primary py-10 lg:py-20 my-10 lg:my-20">
      <div className="flex mx-auto justify-start items-start flex-col gap-2 w-[90%] max-w-[800px]">
        <h3 className="text-white textSmall">Communicate</h3>
        <h3 className="text-[25px] sm:text-[30px] lg:text-[48px] font-normal font-manrope leading-[1.5] text-white">
          Talk the Talk, Walk the Walk: How to Communicate Effectively as a
          Resident
        </h3>
        <p className="textSmall text-tertiary">
          Effective communication is essential for being a good resident. Make
          sure to keep open lines of communication with your neighbors, local
          officials, and community leaders. Listen to their concerns and ideas,
          and be respectful when voicing your own opinions.
        </p>
      </div>
    </div>
  );
};

export default Communicate;
