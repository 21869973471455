import React from "react";
import { useHistory } from "react-router-dom";

const Find = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/aboutus");
  };
  return (
    <div className="wrapper mt-[60px] lg:mt-[120px] py-[100px] bg-find bg-cover bg-center bg-no-repeat">
      <div className="w-full text-center px-5 max-w-[700px] flex justify-center items-center flex-col gap-5">
        <h2 className="title leading-[1] text-white">
          Find Best Place For Living
        </h2>
        <p className="text-[#EDEFF6] text-base sm:text-xl">
          Spend vacations in best hotels and resorts find the great place of
          your choice using different searching options.
        </p>
        <button
          className="bg-transparent hover:bg-white text-white hover:text-black border border-white border-solid transition-all duration-300 px-6 py-3 rounded text-base font-medium"
          onClick={handleClick}
        >
          Contact Us
        </button>
      </div>
    </div>
  );
};

export default Find;
