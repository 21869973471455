import { useState, useEffect } from "react";
import axios from "axios";
import { BASELINE, AFTER_LOGIN } from "../../util/index";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

const Articles = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    loadArticles();
  }, []);

  const loadArticles = () => {
    axios
      .post(BASELINE + "get/articles2", {})
      .then(function (response) {
        const modifiedData = response.data.articles.map((post) => ({
          id: post.id,
          title: post.title,
          content: post.content,
          date: post.date,
          image: post.path,
          language: post.language,
        }));
        setPosts(modifiedData);
      })
      .catch(function (error) {
        alert(error);
      });
  };

  return (
    <div className="wrapper my-[40px] sm:my-[80px]">
      <div className="flex justify-center items-center flex-col gap-2 text-center">
        <h2 className="title text-[1E2640]">Blog articles</h2>
        <p className="text-gray max-w-[700px] text-xl">
          Introducing TopRealty: Your premier house management service.
        </p>
      </div>
      <div className="w-full mt-10 sm:mt-14 pl-3">
        <Splide
          hasTrack={false}
          options={{
            width: "100%",
            gap: "2rem",
            autoWidth: true,
            pagination: false,
            arrows: true,
            type: "loop",
            drag: true,
            perMove: 1,
          }}
          className="pb-[30px]"
          aria-label="..."
        >
        <SplideTrack>
          {posts.slice(0, 5).map((post) => (
            <SplideSlide key={post.id}>
              <SliderBox
                id={post.id}
                img={post.image}
                language={post.language}
                date={post.date}
                hot
              />
            </SplideSlide>
          ))}
        </SplideTrack>

          <div className="splide__arrows absolute top-full left-1/2 -translate-x-1/2 flex justify-center items-center gap-5">
            <button className="static translate-y-0 translate-x-0 bg-brownGr opacity-100 w-[44px] aspect-square h-auto rounded splide__arrow splide__arrow--prev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="white"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
              </svg>
            </button>
            <button className="static translate-y-0 translate-x-0 bg-brownGr opacity-100 w-[44px] aspect-square h-auto rounded splide__arrow splide__arrow--next">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="white"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
              </svg>
            </button>
          </div>
        </Splide>
      </div>
    </div>
  );
};

export default Articles;

const SliderBox = ({ id, img, hot, language, date }) => {
  return (
    <a href={AFTER_LOGIN + "/article/" + id}>
    <div className="w-[312px] h-[425px] relative rounded-[6px] overflow-hidden">
      <img src={img} className="w-full h-full object-cover" alt="" />
      <div className="bg-articleOverlay absolute top-0 left-0 z-10 w-full h-full"></div>
      {hot && (
        <div className="bg-brownGr z-20 text-sm font-normal text-white  w-[90px] h-[32px] rounded-sm flex justify-center items-center absolute top-3 left-3">
          Blog Post
        </div>
      )}
      <div className="flex justify-between z-20  items-center w-full px-5 absolute bottom-6 left-0">
        <div className="flex justify-start text-white items-center gap-2">

          <p className="font-medium">Language: {language}</p>
        </div>
        <div className="flex justify-start text-white items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16"> <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" /> </svg>

          <p className="font-medium">{date}</p>
        </div>
      </div>
    </div>
    </a>
  );
};
