const Hero = () => {
  return (
    <div className="py-14 sm:py-20 lg:py-32 bg-primary wrapper">
      <div className="flex mx-auto justify-start items-start flex-col gap-2 w-[90%] max-w-[800px]">
        <h3 className="text-white textSmall">Top realty Co</h3>
        <h3 className="text-[25px] sm:text-[30px] lg:text-[48px] font-normal font-manrope leading-[1.5] text-white">
          Becoming a Successful Landlord
        </h3>
        <p className="textSmall text-tertiary">
          Becoming a landlord can be a lucrative investment, but it also comes
          with its own set of challenges. As a landlord, you are responsible for
          finding and screening tenants, maintaining your property, and handling
          any disputes that may arise. Here are a few tips to help you become a
          successful landlord
        </p>
        <div className="flex justify-start items-start flex-col gap-8 w-full mt-6">
          <Row
            img="/grid3.png"
            title="Screen your tenants carefully"
            desc=" One of the most important things you can do as a landlord is to thoroughly screen your tenants. This includes running credit and background checks, verifying employment and income, and checking references from previous landlords."
          />
          <Row
            img="/grid2.png"
            title="Keep your property in good condition"
            desc="Your property is your investment, so it's important to keep it in good condition. This includes making any necessary repairs, keeping the property clean, and addressing any issues promptly."
          />
          <Row
            img="/dispute.png"
            title="Handle disputes fairly"
            desc="Despite your best efforts, disputes may still arise between you and your tenants. When this happens, it's important to handle the situation fairly and professionally."
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;

const Row = ({ img, title, desc }) => {
  return (
    <div className="flex sm:flex-row flex-col justify-start items-center gap-6">
      <img
        src={img}
        className="w-[150px] min-w-[150px] h-[150px] object-contain"
        alt=""
      />
      <div className="flex justify-start items-start flex-col gap-2">
        <h3 className="text-white text-2xl sm:text-[28px] font-manrope font-normal">
          {title}
        </h3>
        <p className="text-white text-base sm:text-xl font-normal">{desc}</p>
      </div>
    </div>
  );
};
