import React from "react";
import { AFTER_LOGIN } from "../../util";

const BlogCard = ({ title, date, image, id }) => {
  return (
    <a href={AFTER_LOGIN + "/article/" + id}>
      <div className="bg-white rounded-lg shadow-md p-4">
        <div className="w-full h-32 bg-cover bg-center rounded-t-lg" style={{ backgroundImage: `url(${image})` }}></div>
        <div className="mt-4 font-bold text-lg">{title}</div>
        <div className="mt-2 text-sm text-gray-500">{date}</div>
      </div>
    </a>
  );
};
 
export default BlogCard;