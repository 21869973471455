const Hero = () => {
  return (
    <>
      <div className="wrapper bg-primary py-[120px] sm:py-[170px]">
        <div className="contain flex-col relative text-center items-center justify-center gap-5">
          <h4 className="text-tertiary textSmall">About</h4>
          <h3 className="titleBig max-w-[850px]">
            How to be a Good Resident: Tips and Advice
          </h3>
          <p className="text-tertiary max-w-[750px]">
            As a resident, you have the opportunity to gain valuable experience
            and knowledge while serving your community.
          </p>
          <div className="lg:mt-0 mt-5 grid top-full lg:translate-y-[10%] gap-x-5 lg:absolute grid-cols-1 lg:grid-cols-3 grid-row-5 lg:grid-rows-2 w-full place-items-center gap-y-5 lg:gap-y-4">
            <img
              src="/grid1.png"
              className="w-full max-w-[300px] object-contain self-start"
              alt=""
            />
            <img
              src="/grid-mid.png"
              className="lg:row-span-2 col-span-1 lg:col-start-2 h-auto lg:max-w-none max-w-[300px] lg:h-full w-full  object-cover rounded-2xl lg:row-start-1 row-start-3"
              alt=""
            />
            <img
              src="/grid2.png"
              className="w-full max-w-[300px] object-contain self-start"
              alt=""
            />
            <img
              src="/grid3.png"
              className="w-full max-w-[300px] object-contain self-end"
              alt=""
            />
            <img
              src="/grid4.png"
              className="w-full max-w-[300px] object-contain self-end"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="flex mx-auto mt-10 lg:mt-[620px] justify-start items-start flex-col gap-2 w-[90%] max-w-[800px]">
        <h3 className="text-secondary textSmall">Get Involved</h3>
        <h3 className="text-[25px] sm:text-[30px] lg:text-[48px] font-normal font-manrope leading-[1.5] text-black">
          One of the best ways to be a good resident is to get involved in your
          community.
        </h3>
        <p className="textSmall text-secondary">
          Attend local events, join community organizations, and volunteer your
          time to help out where you can. This will not only help you meet new
          people and make connections, but it will also show that you are
          committed to making a positive impact in your community.
        </p>
      </div>
    </>
  );
};

export default Hero;
