import Communicate from "./Communicate";
import Hero from "./Hero";
import Informed from "./Informed";
import Header2 from "../../layout/Header2";
import TenantFaq from "./TenantFaq";

const Residents = () => {
  return (
    <>
    <Header2 />
    <div className="mb-[100px] flex flex-col justify-center items-center bg-blogBanner text-white h-72 gap-6 bg-center bg-no-repeat bg-cover">
        <h1 className="font-bold text-4xl">Residents</h1>
        <p>Home / Residents</p>
      </div>
      <Hero />
      <Communicate />
      <Informed />
      <TenantFaq />
    </>
  );
};

export default Residents;
