import Hero from "./Hero";
import Section from "../../components/Section";
import Tips from "./Tips";
import Header2 from "../../layout/Header2";
import LandlordFaq from "./LandlordFaq";

const Landlord = () => {
  return (
    <>
    <Header2 />
    <div className="mb-[100px] flex flex-col justify-center items-center bg-blogBanner text-white h-72 gap-6 bg-center bg-no-repeat bg-cover">
        <h1 className="font-bold text-4xl">Landlords</h1>
        <p>Home / Landlords</p>
      </div>
      <Hero />
      <div className="contain lg:flex-row flex-col mx-auto justify-between items-center gap-8 sm:gap-14 py-14 sm:py-20 lg:py-32">
        <img
          src="/entity.png"
          className="w-full max-w-[500px] object-contain"
          alt=""
        />
        <h3 className="text-[25px] sm:text-[30px] xl:text-[48px] font-normal font-manrope leading-[1.5] text-black">
          A landlord is an individual or entity that owns and rents out property
          to tenants.
        </h3>
      </div>
      <Tips />
      <Section
        classes="my-8 sm:my-20"
        title="If a tenant falls behind"
        desc="If a tenant falls behind on rent payments, it's important to take action promptly. Reach out to the tenant to discuss the situation and try to work out a payment plan or other arrangement. If the tenant continues to be delinquent on rent, you may need to initiate eviction proceedings. Again, it's important to consult with an attorney to ensure that you follow all applicable laws and regulations."
        img="/locate.png"
      />
      <LandlordFaq />
    </>
  );
};

export default Landlord;
