import React, { useState } from "react";
import Header2 from "../../layout/Header2";
import axios from "axios";
import { BASELINE, AFTER_LOGIN } from "../../util";

function AdminLogin() {
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");

    const handleIdChange = (event) => {
        setId(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        check_login(id, password); // Call the check_login function with the username and password
    };

    const check_login = (username, password) => {
        var data = {
          username: username,
          password: password
        };
      
        axios.post(BASELINE + "admin/login", data)
          .then(function (response) {
            alert(response.data.message);
            if (response.data.message === "Login") {
              // Save the token to local storage
              const token = response.data.token;
              localStorage.setItem('accessToken', token);
      
              window.location.replace(AFTER_LOGIN + '/admin/dashboard'); // Redirect the user to the dashboard page
            }
          })
          .catch(function (error) {
            alert(error);
          });
      };

    return (
        <>
            <Header2 />
            <div className="max-w-md mx-auto mt-8">
                <h1 className="text-2xl font-semibold mb-4">Top Realty Admin Login</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-bold mb-2" htmlFor="id">
                            ID
                        </label>
                        <input
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="id"
                            type="text"
                            value={id}
                            onChange={handleIdChange}
                        />
                    </div>
                    <div className="mb-6">
                        <label
                            className="block text-gray-700 font-bold mb-2"
                            htmlFor="password"
                        >
                            Password
                        </label>
                        <input
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="password"
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="submit"
                        >
                            Login
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}

export default AdminLogin;