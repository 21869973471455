const Informed = () => {
  return (
    <div className="wrapper mb-10 lg:mb-20">
      <div className="contain flex-col justify-center items-center text-center gap-5">
        <h3 className="text-darkBlue textSmall">
          How can I stay informed about local news and events?
        </h3>
        <h3 className="text-[25px] sm:text-[30px] lg:text-[48px] font-normal font-manrope leading-[1.5] text-darkBlue">
          Homeownership in America: Exploring the Latest Statistics and Trends
        </h3>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 w-full">
          <div className="flex justify-center items-center flex-col gap-0">
            <h3 className="text-darkBlue font-manrope font-normal text-[50px] sm:text-[96px]">
              3 in 5
            </h3>
            <p className="text-lg sm:text-2xl font-normal text-darkBlue">
              US Businesses Use Management Systems
            </p>
          </div>
          <div className="flex justify-center items-center flex-col gap-0">
            <h3 className="text-darkBlue font-manrope font-normal text-[50px] sm:text-[96px]">
              40%
            </h3>
            <p className="text-lg sm:text-2xl font-normal text-darkBlue">
              of businesses reported using such systems
            </p>
          </div>
          <div className="flex justify-center items-center flex-col gap-0">
            <h3 className="text-darkBlue font-manrope font-normal text-[50px] sm:text-[96px]">
              64.5%
            </h3>
            <p className="text-lg sm:text-2xl font-normal text-darkBlue">
              the home ownership rate in the United States
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Informed;
