import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASELINE } from "../../util/index";

const TestimonialMedia = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    function callApi() {
      axios.get(BASELINE + "get/images", {})
        .then(function (response) {
          console.log(response.data.imagesPaths);

          const imgs = []
          for (var i = 0; i < response.data.imagesPaths.length; i++) {
            imgs.push(<img key={i} src={response.data.imagesPaths[i]} />);
          }
          console.log(imgs);
          setImages(imgs);
        })
        .catch(function (error) {
          alert(error);
        });

    }

    callApi();
  }, []);

  return (
    <div className="wrapper gap-6 px-6 sm:px-8 xl:px-12 mt-[40px] sm:mt-[60px]">
      <h2 className="testimonial-heading text-3xl sm:text-4xl">Media</h2>

      <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-6 gap-5 bg-silver p-0 sm:px-[20px] xl:px-[40px] sm:py-[40px] xl:py-[55px]">
        {images}
      </div>
    </div>
  );
};

export default TestimonialMedia;
