import React, { useEffect, useState } from "react";
import axios from "axios";
import Review from "../../components/Review";
import { BASELINE } from "../../util";

const TestimonialReviews = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    axios
      .get(BASELINE + "review/get")
      .then((response) => {
        const { reviews } = response.data;
        setReviews(reviews);
      })
      .catch((error) => {
        console.error("Error fetching reviews:", error);
      });
  }, []);

  return (
    <div className="wrapper mt-[60px] px-[25px] sm:px-[50px] xl:px-[88px] gap-6">
      <h2 className="testimonial-heading text-3xl sm:text-4xl">Reviews</h2>

      <div className="grid grids-col-1 sm:grid-cols-2 xl:grid-cols-3 gap-10">
        {reviews.map(({ company, companyAlt, photo, reviewedBy, review }, index) => (
          <Review
            key={index}
            company={`/${company.toLowerCase()}.png`}
            companyAlt={companyAlt}
            photo={BASELINE + photo}
            reviewedBy={reviewedBy}
            review={review}
          />
        ))}
      </div>
    </div>
  );
};

export default TestimonialReviews;
