import { useState, useEffect } from "react";
import axios from "axios";
import BlogList from "./BlogList";
import {BASELINE} from "../../util/index";

const BlogDetails = () => {
  const [filter, setFilter] = useState("Eng");
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    loadArticles();
  }, []);

  const loadArticles = () => {
    axios
      .post(BASELINE + "get/articles2", {})
      .then(function (response) {
        const modifiedData = response.data.articles.map((post) => ({
          id: post.id,
          title: post.title,
          content: post.content,
          date: post.date,
          image: post.path,
          language: post.language,
        }));
        setPosts(modifiedData);
      })
      .catch(function (error) {
        alert(error);
      });
  };

  let componentToRender;
  
  if (filter === "Eng") {
    componentToRender = <BlogList lang="en" articles={posts}/>;
  } else if (filter === "Kor") {
    componentToRender = <BlogList lang="ko" articles={posts}/>;
  } else if (filter === "Chn") {
    componentToRender = <BlogList lang="zh" articles={posts}/>;
  }
  
  return (
    <div className="wrapper mt-[70px] px-5 md:px-[50px] lg:px-[70px] xl:px-[100px]">
      <div className="wrapper max-w-[1500px] gap-8 md:gap-12 xl:gap-16">
        <div className="flex gap-8 xl:gap-16 w-full">
          <div
            className={`cursor-pointer text-xl font-semibold ${
              filter === "Eng"
                ? "border-b border-navyBlue bg-brownGr bg-clip-text text-transparent uppercase"
                : "text-lightBlack opacity-70"
            }`}
            onClick={() => setFilter("Eng")}
          >
            Eng
          </div>
          <div
            className={`cursor-pointer text-xl font-semibold ${
              filter === "Kor"
                ? "border-b border-navyBlue bg-brownGr bg-clip-text text-transparent uppercase"
                : "text-lightBlack opacity-70"
            }`}
            onClick={() => setFilter("Kor")}
          >
            Kor
          </div>
          <div
            className={`cursor-pointer text-xl font-semibold ${
              filter === "Chn"
                ? "border-b border-navyBlue bg-brownGr bg-clip-text text-transparent uppercase"
                : "text-lightBlack opacity-70"
            }`}
            onClick={() => setFilter("Chn")}
          >
            Chn
          </div>
        </div>

        <div className="w-full flex flex-col gap-6">
          {componentToRender}
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;