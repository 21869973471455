import React, { useState } from "react";
import axios from "axios";
import { BASELINE } from "../../util/index";
const Hero = () => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    housingType: "",
    address: "",
    apt: "",
    state: "",
    zip: ""
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    var data = {
      address: formData.get("address"),
      apt: formData.get("apt"),
      state: formData.get("state"),
      zip: formData.get("zip"),
      name: formData.get("name"),
      phone: formData.get("phone"),
      email: formData.get("email"),
      housingType: formData.get("housingType")
    }

    const formEle = document.querySelector("form");
    const formDatab = new FormData(formEle);
    fetch("https://script.google.com/macros/s/AKfycbwUdyD0q-yvFhRK3OmbKesMfnQji8njJm5PQTLwRhc9T7AG3xiD4X8KuTTKjXllmaAlOA/exec", {
      method: "POST",
      body: formDatab
    }).then((res) => res.json())
      .then((data) => {
        alert("Successfully sent the info to the manager");
        window.location.reload();
      })
      .catch((error) => {
        alert("Successfully sent the info to the manager");
        window.location.reload();
      });
  };

  return (
    <div className="xl:h-screen pb-8  wrapper bg-hero bg-cover bg-no-repeat min-h-screen pt-[200px] xl:p-0 xl:min-h-[800px]">
      <div className="relative flex justify-center gap-8 sm:gap-16 xl:gap-0 items-center h-full flex-col w-full max-w-[1375px] px-5">
        <h2 className="title text-white xl:h-full flex flex-col justify-center items-center">
          Property Management Charges a
          <h2 className="text-4xl md:text-2xl lg:text-3xl xl:text-4xl flex items-center">
            5% Management Fee
          </h2>
        </h2>



        <div className="flex gap-5 justify-center items-center flex-col w-full">
          <div className="gap-5 sm:gap-16 w-full flex sm:flex-row flex-col justify-center items-center">
            <button className="text-white text-base font-medium sm:w-auto w-full bg-brownGr px-6 py-3 rounded border-none">
              Help to maximize your ROI
            </button>
          </div>

          <form className="form" onSubmit={(e) => handleSubmit(e)}>
            <div className="flex gap-5 xl:gap-0 flex-col xl:flex-row xl:translate-y-1/2 xl:-mt-[60px] justify-between items-center w-full bg-white rounded-lg py-5 px-4">
              <div className="flex sm:px-5  justify-start items-start w-full flex-col gap-3">
                <h3 className="text-darkBlue text-base font-semibold">Address</h3>
                <div className="flex justify-start items-center flex-col w-full gap-3">
                  <input
                    type="text"
                    placeholder="Type Your Address"
                    className="input text-center"
                    name="Address"
                  />
                  <div className="grid grid-cols-[1fr__2fr__1fr] w-full gap-3">
                    <input
                      type="text"
                      placeholder="APT."
                      className="input text-center"
                      name="Apt"
                    />
                    <input
                      type="text"
                      placeholder="State"
                      className="input text-center"
                      name="State"
                    />
                    <input
                      type="text"
                      placeholder="Zip"
                      className="input text-center"
                      name="Zip"
                    />
                  </div>
                </div>
              </div>
              <div className="flex sm:px-5 sm:flex-row flex-col xl:border-x border-solid border-[#CFD3D6] justify-center items-center gap-5 sm:gap-3 w-full">
                <div className="flex justify-start items-start flex-col gap-3 w-full">
                  <h3 className="text-darkBlue text-base font-semibold">Name</h3>
                  <input
                    type="text"
                    className="input pl-2"
                    placeholder="First & Last Name"
                    name="Name"
                  />
                </div>
                <div className="flex justify-start items-start flex-col gap-3 w-full">
                  <h3 className="text-darkBlue text-base font-semibold">Phone#</h3>
                  <input
                    type="text"
                    className="input pl-2"
                    placeholder="Phone Number"
                    name="PhoneNumber"
                  />
                </div>
                <div className="flex justify-start items-start flex-col gap-3 w-full">
                  <h3 className="text-darkBlue text-base font-semibold">Email</h3>
                  <input
                    type="text"
                    className="input text-center"
                    placeholder="Email"
                    name="Email"
                  />
                </div>
              </div>
              <div className="flex xl:max-w-[220px] sm:px-5 justify-start items-start flex-col xl:border-r border-solid border-[#CFD3D6] gap-3 w-full">
                <h3 className="text-darkBlue text-base font-semibold">
                  Housing Type
                </h3>
                <select
                  className="input"
                  name="HousingType"
                >
                  <option value="">Select housing type</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
              <button className="text-white ml-5 text-base font-medium bg-brownGr px-6 py-3 rounded border-none" type="submit" >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Hero;
