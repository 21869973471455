const Tips = () => {
  return (
    <div className="wrapper bg-primary py-14 sm:py-20 lg:py-32 ">
      <div className="flex w-[90%] max-w-[1200px] flex-col justify-center items-center gap-6 sm:gap-10">
        <div className="flex mx-auto text-center justify-start items-center flex-col gap-2 w-[90%] max-w-[800px]">
          <h3 className="text-white textSmall">Our Tips</h3>
          <h3 className="text-[25px] sm:text-[30px] lg:text-[48px] font-normal font-manrope leading-[1.5] text-white">
            Additional Informations that landlords may wonder
          </h3>
        </div>
        <div className="bg-white w-full h-[1px]"></div>
        <Row
          title="Attracting Good Tenants"
          desc="To attract good tenants to your property, it's important to market your property effectively. This includes taking high-quality photos of the property, highlighting its best features, and posting the listing on popular rental websites."
        />
        <Row
          title="Dealing with Lease"
          desc="If a tenant breaks the lease agreement, it's important to take prompt action. Review the terms of the lease agreement and determine if the tenant's actions constitute a breach of the agreement."
        />
        <Row
          title="Effective Repairs"
          desc="As a landlord, you are responsible for making necessary repairs and maintaining the property in good condition. When a tenant requests a repair, respond promptly and take care of the issue as soon as possible."
        />
      </div>
    </div>
  );
};

export default Tips;

const Row = ({ title, desc }) => {
  return (
    <div className="grid items-start grid-cols-1 lg:grid-cols-2 w-full gap-3 lg:gap-10 ">
      <h3 className="text-[25px] sm:text-[35px] lg:text-[48px] pt-6 leading-[1.2] font-normal font-manrope text-white">
        {title}
      </h3>
      <p className="text-white textSmall">{desc}</p>
    </div>
  );
};
