import React, { useEffect, useState } from "react";
import Review_edit from "./review_edit";
import ReviewForm from "./review_form";
import ReviewRemove from "./review_remove";
import { BASELINE } from "../../util";
import axios from "axios";

const ReviewDashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedReview(null);
  };

  const handleAddReview = (newReview) => {
    const formData = new FormData();
    formData.append("company", newReview.company);
    formData.append("companyAlt", newReview.companyAlt);
    formData.append("reviewedBy", newReview.reviewedBy);
    formData.append("review", newReview.review);
    formData.append("photo", newReview.photo);

    axios
      .post(BASELINE + "review/add", formData)
      .then((response) => {
        alert("Review added successfully!");
        fetchReviews();
        closeModal();
      })
      .catch((error) => {
        alert("Error adding review:", error);
      });
  };

  const removeReview = (id) => {
    const confirmRemove = window.confirm("Are you sure you want to remove this review?");
    if (confirmRemove) {
      axios
        .delete(`${BASELINE}review/remove/${id}`)
        .then((response) => {
          alert("Review removed successfully!");
          fetchReviews();
        })
        .catch((error) => {
          alert("Error removing review:", error);
        });
    }
  };

  const updateReview = (updatedReview) => {
    const formData = new FormData();
    formData.append("company", updatedReview.company);
    formData.append("companyAlt", updatedReview.companyAlt);
    formData.append("reviewedBy", updatedReview.reviewedBy);
    formData.append("review", updatedReview.review);
    formData.append("photo", updatedReview.photo);

    axios
      .put(`${BASELINE}review/update/${updatedReview.id}`, formData)
      .then((response) => {
        alert("Review updated successfully!");
        fetchReviews();
        closeModal();
      })
      .catch((error) => {
        alert("Error updating review:", error);
      });
  };

  const fetchReviews = () => {
    axios
      .get(BASELINE + "review/get")
      .then((response) => {
        const { reviews } = response.data;
        setReviews(reviews);
      })
      .catch((error) => {
        console.error("Error fetching reviews:", error);
      });
  };

  const handleReviewCardClick = (review) => {
    setSelectedReview(review);
    setIsModalOpen(true);
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  return (
    <div>
      <div>
        {/* Other content */}
        <button className="bg-blue-500 text-white py-2 px-4 rounded-lg mt-4" onClick={openModal}>
          Add Review
        </button>
        {isModalOpen && (
          <ReviewForm
            onAddReview={handleAddReview}
            onUpdateReview={updateReview}
            onClose={closeModal}
            selectedReview={selectedReview}
          />
        )}
      </div>

      {/* Render Reviews */}
      <div className="grid grids-col-1 sm:grid-cols-2 xl:grid-cols-3 gap-6">
        {reviews.map((review) => (
          <div key={review.id}>
            <ReviewRemove id={review.id} onRemoveReview={removeReview} />
            <Review_edit
              company={`/${review.company.toLowerCase()}.png`}
              companyAlt={review.companyAlt}
              photo={BASELINE + review.photo}
              reviewedBy={review.reviewedBy}
              review={review.review}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewDashboard;
