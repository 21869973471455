import { useState } from 'react';

function DocumentationCreator() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [repairs, setRepairs] = useState([]);
    const houseFloorLocations = [
        'Living Room',
        'Kitchen',
        'Bathroom',
        'Bedroom',
        'Dining Room',
        'Home Office',
        'Laundry Room',
        'Garage',
        'Basement',
        'Attic',
        'Patio',
        'Garden',
        'Balcony',
        'Front Porch',
        'Backyard',
        'Stairs',
        'Hallway',
        'Closet',
        'Study',
        'Playroom',
        'Media Room',
        'Guest Room',
        'Mudroom',
        'Foyer',
        'Pantry',
        'Utility Room',
        'Sunroom',
        'Cellar',
        'Shed',
        'Roof',
        'Deck',
    ];

    const handleAddRepair = () => {
        setRepairs([...repairs, { location: '', note: '', price: 0, photo: null }]);
      };
    
      const handleLocationChange = (index, value) => {
        const updatedRepairs = [...repairs];
        updatedRepairs[index].location = value;
        setRepairs(updatedRepairs);
      };
    
      const handleNoteChange = (index, value) => {
        const updatedRepairs = [...repairs];
        updatedRepairs[index].note = value;
        setRepairs(updatedRepairs);
      };
    
      const handlePriceChange = (index, value) => {
        const updatedRepairs = [...repairs];
        updatedRepairs[index].price = value;
        setRepairs(updatedRepairs);
      };
    
      const handlePhotoChange = (index, event) => {
        const updatedRepairs = [...repairs];
        updatedRepairs[index].photo = event.target.files[0];
        setRepairs(updatedRepairs);
      };
    
      const calculateTotalPrice = () => {
        return repairs.reduce((total, repair) => total + repair.price, 0);
      };
    

    // Function to handle form submission

    return (
        <div>

            <h2>{`Title: ${new Date().toLocaleDateString()} - ${firstName} ${lastName}`}</h2>
            <input type="hidden" value={new Date().toLocaleDateString()} />
            <input type="hidden" value={`${firstName} ${lastName}`} />
            <table>
                <thead>
                    <tr>
                        <th>Location</th>
                        <th>Note</th>
                        <th>Price</th>
                        <th>Photo</th>
                    </tr>
                </thead>
                <tbody>
                    {repairs.map((repair, index) => (
                        <tr key={index}>
                            <td>
                                <select
                                    value={repair.location}
                                    onChange={(e) => handleLocationChange(index, e.target.value)}
                                >
                                    {houseFloorLocations.map((location, index) => (
                                        <option key={index} value={location}>
                                            {location}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={repair.note}
                                    onChange={(e) => handleNoteChange(index, e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={repair.price}
                                    onChange={(e) => handlePriceChange(index, e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handlePhotoChange(index, e)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button onClick={handleAddRepair}>Add Repair</button>
            <p>Total Price: {calculateTotalPrice()}</p>
            {/* Form submission button */}
        </div>
    );
}
export default DocumentationCreator;