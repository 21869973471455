const Online = () => {
  return (
    <div className="wrapper mt-12">
      <div className="contain flex-col justify-start items-center gap-8">
        <img src="/home3.png" className="w-full object-contain" alt="" />
        <div className="flex lg:flex-row flex-col justify-center items-center gap-6 sm:gap-10">
          <h3 className="text-center leading-[1] text-[#AC1D1D] font-bold text-[80px] sm:text-[120px]">
            100% <br /> Online
          </h3>
          <p className="text-black textSmall">
            Our property management platform is designed to provide seamless and
            convenient access to tenants and landlords. Our user-friendly
            platform is accessible 24/7/365, allowing tenants to easily pay rent
            and request maintenance services. Landlords can access a dashboard
            that provides real-time accounting information, as well as a direct
            line of communication with our staff members. This ensures that any
            issues are addressed quickly and efficiently, minimizing the impact
            on both tenants and landlords. At our property management company,
            we are dedicated to providing a hassle-free and streamlined
            experience for all parties involved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Online;
