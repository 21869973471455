import Hero from "./Hero";
import Services from "./Services";
import Video from "./Video";
import Header from "../../layout/Header";
import Articles from "./Articles";
import Customer from "./Customer";
import About from "./About";
import Online from "./Online";
import Find from "./Find";
import Section from "../../components/Section";

const Index = () => {
  return (
    <>
      <Header />
      <Hero />
      <Video />
      <Services />
      <Section
        classes="my-8 sm:my-20"
        title="Locate Tenants"
        desc="Our Top Realty Co. offers a comprehensive marketing strategy to help landlords quickly locate tenants for their properties. We utilize aggressive promotion on various social media platforms and online agencies to maximize the property's exposure, ensuring it receives maximum visibility to attract numerous responses every day. Our daily updated advertisements provide landlords with numerous responses, helping them rent their property as quickly as possible."
        img="/locate.png"
      />
      <Section
        reverse
        classes="mt-12 sm:mt-32"
        img="/qualify.png"
        title="Qualifying"
        desc="At Top Realty Co., we believe in selecting only the best tenants for your property. To ensure that we do so, we conduct all the showings ourselves, giving us the opportunity to meet with potential tenants in person and get a sense of their personality and suitability. Additionally, we run multiple background checks on all applicants and verify their employment and rental history before signing any leases, ensuring that only the most qualified tenants are selected."
      />
      <Online />
      <About />
      <Articles />
      <Customer />
      <Find />
    </>
  );
};

export default Index;
