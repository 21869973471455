import React from "react";
import BlogCard from "./BlogCard";

const BlogList = ({ articles, lang }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
      {articles.map((article) => {
        if (article.language === lang || lang === "Eng") {
          return (
            <BlogCard 
              id={article.id} 
              title={article.title} 
              date={article.date} 
              image={article.image} 
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default BlogList;
