import React, { Component } from "react";
import { withRouter } from "react-router";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import axios from "axios";
import { BASELINE, CLIENT_URL } from "../../util";

class BlogEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blog_article_id: "",
      title: "",
      title_img: "",
      title_img_loc: "",
      context: "",
      subject: "",
      date: "",
      notion: ""
    };
    this.uploadAdapter = this.uploadAdapter.bind(this);
    this.updatearticle = this.updatearticle.bind(this);
    this.settitle = this.settitle.bind(this);
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  settitle = (e) => {
    this.setState({ title: e.target.value });
  };

  async updatearticle() {
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    this.setState({ date: date });
    this.state.date = date;

    const formData = new FormData();
    formData.append("title_img", this.state.title_img_loc);
    formData.append("aid", this.state.blog_article_id);
    formData.append("title", this.state.title);
    formData.append("date", this.state.date);
    formData.append("subject", this.state.subject);
    formData.append("context", this.state.context);
    formData.append("form", this.state.form);
    formData.append("notion", this.state.notion);

    axios.post(BASELINE + "edit/update/article", formData)
      .then(function (response) {
        alert(response.data.message);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("files", file);
            body.append("aid", this.state.blog_article_id);
            axios.post(BASELINE + "upload/image", body)
              .then(function (response) {
                resolve({ default: `${CLIENT_URL}/${response.data.link}` });
              })
              .catch(function (error) {
                reject(error);
              });
          });
        });
      },
    };
  }
  handleChangeImage = (e) => {
    this.setState({ [e.target.name]: URL.createObjectURL(e.target.files[0]) });
    this.setState({ title_img_loc: e.target.files[0] });
  };

  fetchData = (id) => {
    var data = {
      id: id
    }
    var self = this;
    axios.post(BASELINE + "edit/get/article", data)
      .then(function (response) {
        self.setState({ title: response.data.title });
        self.setState({ title_img: response.data.title_img });
        self.setState({ context: response.data.context });
        self.setState({ subject: response.data.subject });
        self.setState({ blog_article_id: response.data.aid });
        self.setState({ form: response.data.form });
        self.setState({ notion: response.data.notion });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  componentDidMount() {
    this.fetchData(this.props?.aid);
  }

  render() {
    return (
      <div>
        <div className="container mx-auto p-5 md:p-9 rounded-2xl bg-white">
          <div className="flex flex-col gap-10 md:flex-row items-center text-slate-400">
            <div className="rounded-2xl p-6 h-36 sm:max-w-[200px] max-w-[250px] text-sm text-slate-400 bg-white border border-dashed mb-3 border-slate-500 flex justify-center items-center">
              {this.state.title_img == "" && <p>+ Drag and drop or click here to upload image</p>}{" "}
              {this.state.title_img != "" && <img src={this.state.title_img} alt="img" />}
              <input
                type="file"
                name="title_img"
                accept="image/*"
                onChange={this.handleChangeImage}
              />
            </div>
            <div className="w-full">
              <input
                type="text"
                id=""
                className="bg-gray-50 border border-gray-500 text-gray-900 text-sm rounded-md outline-none focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-full md:w-[451px]"
                placeholder="Title"
                value={this.state.title}
                onChange={this.settitle}
              />
            </div>
          </div>
          <button
            className="md:mt-0 ml-auto items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-[#374557] rounded-lg focus:ring-4 duration-300 hover:bg-[#27313e] focus:ring-blue-200 mb-2"
            onClick={() => {
              this.props?.handleBackClick(0);
            }}
          >
            Back
          </button>
          <button
            className="ml-10 mt-8 md:mt-0 items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-[#374557] rounded-lg focus:ring-4 duration-300 hover:bg-[#27313e] focus:ring-blue-200 mb-10"
            onClick={this.updatearticle}
          >
            Update the Article
          </button>


          <CKEditor
            editor={DecoupledEditor}
            data={this.state.context}
            onReady={(editor) => {
              editor.ui
                .getEditableElement()
                .parentElement.insertBefore(
                  editor.ui.view.toolbar.element,
                  editor.ui.getEditableElement()
                );

              editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
                return this.uploadAdapter(loader);
              };
            }}
            onChange={(event, editor) => {
              const data = editor.getData();;
              this.setState({ context: data });
            }}
          />


        </div>
      </div>
    );
  }
}

export default BlogEdit