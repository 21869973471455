import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="wrapper mt-[60px] sm:mt-[80px] py-[40px] bg-white">
      <div className="w-full lg:flex-row flex-col  max-w-[1340px] px-5 flex justify-between items-center lg:items-start gap-8  lg:gap-4">
        <div className="flex justify-start lg:text-left text-center items-center lg:items-start flex-col gap-5 ">
          <img
            src="/logo2.png"
            className="w-[220px] mb-2 object-contain"
            alt=""
          />
          <h3 className="text-lightBlack text-2xl font-semibold font-publicSans">
            Contact Us
          </h3>
          <a
            href="tel:123 400 123"
            className="text-[#6d737a] text-base font-normal font-publicSans"
          >
            Call : 971-278-9421
          </a>
          <p className="text-[#6d737a] max-w-[312px] text-base font-normal font-publicSans">
          Top Realty Management is a real estate management company that specializes in providing property management services.
          </p>
          <div className="flex justify-start items-center gap-4">
            <a
              href="https://www.facebook.com/toprealtycoPDX/"
              target="blank"
              className="bg-brownGr w-[48px] aspect-square rounded flex justify-center items-center"
            >
              <img src="/fb.png" alt="" />
            </a>
            <a
              href="https://twitter.com/PromaxPdx"
              target="blank"
              className="bg-brownGr w-[48px] aspect-square rounded flex justify-center items-center"
            >
              <img src="/twitter.png" alt="" />
            </a>
            <a
              href="#"
              target="blank"
              className="bg-brownGr w-[48px] aspect-square rounded flex justify-center items-center"
            >
              <img src="/in.png" alt="" />
            </a>
            <a
              href="https://www.yelp.com/user_details?userid=1-Rbmx5-LtIOvDj7ZZjopg"
              target="blank"
              className="bg-brownGr w-[48px] aspect-square rounded flex justify-center items-center"
            >
              <img src="/syelp.png" alt="" />
            </a>
          </div>
        </div>
        <div className="flex justify-start lg:text-left text-center items-center lg:items-start flex-col gap-5 ">
          <h3 className="text-lightBlack mb-1 text-2xl font-semibold font-publicSans">
            Features
          </h3>
          <Link
            className="text-[#6d737a] text-base font-normal font-publicSans"
            to={"/"}
          >
            Home
          </Link>
          <Link
            className="text-[#6d737a] text-base font-normal font-publicSans"
            to={"/blog"}
          >
            Blog
          </Link>
          <Link
            className="text-[#6d737a] text-base font-normal font-publicSans"
            to={"/aboutus"}
          >
            About
          </Link>
        </div>
        <div className="flex justify-start lg:text-left text-center items-center lg:items-start flex-col gap-5 font-publicSans">
          <h3 className="text-lightBlack mb-1 text-2xl font-semibold">
            Company
          </h3>
          <Link
            className="text-[#6d737a] text-base font-normal font-publicSans"
            to={"/residents"}
          >
            Residents
          </Link>
          <Link
            className="text-[#6d737a] text-base font-normal font-publicSans"
            to={"/landlord"}
          >
            Landlords 
          </Link>
        </div>
        <div className="flex justify-start lg:text-left text-center items-center lg:items-start flex-col gap-5 font-publicSans">
          <h3 className="text-lightBlack mb-1 text-2xl font-semibold">
            Testimonial
          </h3>
          <Link
            className="text-[#6d737a] text-base font-normal font-publicSans"
            to={"/testimonial"}
          >
            Media
          </Link>
          <Link
            className="text-[#6d737a] text-base font-normal font-publicSans"
            to={"/testimonial"}
          >
            Reviews
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
