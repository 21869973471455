import React, { Component } from "react";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { BASELINE } from "../../util/index";
import Header2 from "../../layout/Header2";

class BlogPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 0,
      selectedPage: 1,
      title: "this is the title",
      title_img: '',
      context: "",
      date: ''
    };
  }

  componentDidMount() {
    const aid = this.props.match.params.id; // get the aid value from the URL
    var self = this;
    axios.post(BASELINE + "get/article", { aid })
      .then(function (response) {
        self.setState({ title: response.data.title });
        self.setState({ title_img: response.data.img });
        self.setState({ context: response.data.context });
        self.setState({ date: response.data.date });
      })
      .catch(function (error) {
        alert(error);
      });
  }

  render() {
    return (
      <div>
        <Header2 />
        <div className="flex flex-col justify-center items-center bg-blogBanner text-white h-72 gap-6 bg-center bg-no-repeat bg-cover">
          <h1 className="font-bold text-4xl">BLOG Article</h1>
          <p>Home / Blog / Article</p>
        </div>
        <div className="wrapper mt-16">
          <section className="contain flex-col lg:flex-row lg:gap-8 gap-12">
            <div className="flex-1 flex flex-col gap-10">
              <div>
                <h2 className="text-blogTitle text-2xl leading-7 md:text-4xl font-bold md:leading-[50px]">
                  {this.state.title}
                </h2>
                <div className="text-blogPara text-base sm:text-lg pt-3">
                  {this.state.date}
                </div>
              </div>

              <div>
                <img
                  src={this.state.title_img}
                  alt="interior design"
                  className="pb-8"
                  style={{ width: "500px", height: "350px" }}
                />
                < CKEditor
                  disabled={true}
                  editor={DecoupledEditor}
                  data={this.state.context}
                  onReady={(editor) => { }}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default BlogPost;
