import React, { useState, useRef, useEffect } from "react";
import BentoIcon from "@mui/icons-material/Bento";
import Draggable from 'react-draggable';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { InvoicePdf } from "./pdf/InvoicePdf";
import { View_InvoicePdf } from "./pdf/View_invoice";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import axios from 'axios';
import { BASELINE } from "../../util";

function Repair_dashboard() {
  const [repairs, setRepairs] = useState([]);
  const [getrepairs, setgetrepairs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(1); // 1 for customer information, 2 for house information
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [drawingColor, setDrawingColor] = useState("black");
  const [isDrawing, setIsDrawing] = useState(false);
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const linesCanvasRef = useRef(null);
  const linesContextRef = useRef(null);
  const houseFloorLocations = [
    "Living Room", "Kitchen", "Bathroom", "Bedroom", "Dining Room", "Home Office", "Laundry Room", "Garage", "Basement", "Garden", "Balcony", "Front Porch", "Backyard", "Stairs", "Hallway", "Closet", "Playroom", "Media Room", "Guest Room", "Foyer", "Pantry", "Utility Room", "Sunroom", "Cellar", "Shed", "Roof", "Deck",
  ];

  const [note, setNote] = useState("");
  const [Hours, setHours] = useState("");
  const [price, setPrice] = useState("");
  const [houseLocation, setHouseLocation] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);

  const [Fname, setFname] = useState("");
  const [Lname, setLname] = useState("");
  const [Email, setEmail] = useState("");
  const [Pnum, setPnum] = useState("");
  const [address, setaddress] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [totalPrice2, setTotalPrice2] = useState("");
  const maxRowsPerPage = 15;
  const [totalPages, setTotalPages] = useState(0);
  const [activeRepairId, setActiveRepairId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [copied, setCopied] = useState(false);
  const [namecopied, setnameCopied] = useState(false);

  const handleCopyName = (email) => {
    // Create a temporary input element to copy the email to the clipboard
    const tempInput = document.createElement("input");
    tempInput.value = email;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    // Show the "Copied" alert for 2 seconds
    setnameCopied(true);
    setTimeout(() => setnameCopied(false), 500);
  };

  const handleCopyEmail = (email) => {
    // Create a temporary input element to copy the email to the clipboard
    const tempInput = document.createElement("input");
    tempInput.value = email;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    // Show the "Copied" alert for 2 seconds
    setCopied(true);
    setTimeout(() => setCopied(false), 500);
  };


  const activatePDFDownloadLink = (repair) => {
    setActiveRepairId(repair.id);
  };

  const handleTotalPriceChange = (event) => {
    setTotalPrice2(event.target.value);
  };

  // Function to generate a unique ID
  const generateId = () => {
    // Generate a timestamp-based ID
    const timestamp = Date.now();
    // Generate a random 4-digit number
    const randomNum = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, "0");
    // Combine the timestamp and random number to create the ID
    const id = `${timestamp}-${randomNum}`;
    return id;
  };

  const handleSave = () => {
    if (!note || !price || !houseLocation || !Hours || !totalPrice2) {
      alert("Please fill in all the fields.");
      return;
    }

    const newRepair = {
      id: generateId(),
      houseLocation,
      hours: Hours,
      totalprice: totalPrice2,
      note,
      price,
    };

    setRepairs((prevRepairs) => [...prevRepairs, newRepair]);
    setNote("");
    setHours("");
    setPrice("");
    setTotalPrice2("");
    setHouseLocation("");
    setIsModal2Open(false);
  };

  const handleHouseLocationChange = (event) => {
    setHouseLocation(event.target.value);
  };

  const handleHourChange = (event) => {
    setHours(event.target.value);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  // Function to add a new repair
  const addRepair = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentSlide(1); // Reset current slide to the first slide
    setUploadedPhotos([]);
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    closeModal();

    const formData = new FormData();
    formData.append("issueDate", new Date().toISOString().split("T")[0]);
    formData.append("clientName", `${Fname} ${Lname}`);
    formData.append("email", Email);
    formData.append("phoneNumber", Pnum);
    formData.append("address", address);
    formData.append("repairRequest", JSON.stringify(repairs));
    formData.append("totalPrice", totalPrice);

    uploadedPhotos.forEach(({ file }, idx) => {
      formData.append(`img_${idx}`, file);
    });

    try {
        await axios.post(BASELINE + "add/invoice", formData);
      setNote("");
      setHours("");
      setPrice("");
      setHouseLocation("");
      setTotalPrice(0);
      setFname("");
      setLname("");
      setEmail("");
      setPnum("");
      setaddress("");
      setUploadedPhotos([]);
      setSelectedImage(null);
      alert("Data sent successfully");
      fetchData(); // Call the fetchData function to fetch updated data
      // Handle the response if needed
    } catch (error) {
      console.error("Error sending data:", error);
      // Handle the error if needed
    }
  };

  // Function to navigate to the next slide
  const goToNextSlide = () => {
    setCurrentSlide(currentSlide + 1);
  };

  // Function to navigate to the previous slide
  const goToPreviousSlide = () => {
    setCurrentSlide(currentSlide - 1);
  };

  const handlePhotoUpload = async (e) => {
    const files = Array.from(e.target.files);
    const uploaded = [];

    for (const file of files) {
      try {
        // const base64Image = await convert64(file);
        const img = new Image()
        img.src = URL.createObjectURL(file)

        img.onload = () => {
          uploaded.push({
            file,
            preview: URL.createObjectURL(file),
            height: img.height,
            width: img.width
            // base64Image,
          });

          setUploadedPhotos((prevUploadedPhotos) => [
            ...prevUploadedPhotos,
            ...uploaded,
          ]);
        };
      } catch (error) {
        console.log("Error converting image to base64:", error);
      }
    }

  };

  const removeUploadedPhoto = (index) => {
    setUploadedPhotos((prevUploadedPhotos) => {
      const updatedPhotos = [...prevUploadedPhotos];
      updatedPhotos.splice(index, 1);
      return updatedPhotos;
    });
  };

  // Function to handle photo selection for drawing
  const handleSelectImage = (index) => {
    setSelectedImage(uploadedPhotos[index]);
    // canvasRef.current = uploadedPhotos[index];
  };

  // Function to handle drawing color selection
  const handleSelectColor = (color) => {
    setDrawingColor(color);
  };

  // Function to handle mouse down event on the canvas
  const handleMouseDown = (event) => {
    const canvas = linesCanvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const offsetY = event.clientY - rect.top;
    linesContextRef.current.beginPath();
    linesContextRef.current.moveTo(offsetX, offsetY);
    // linesContextRef.current.lineTo(offsetX, offsetY);
    // linesContextRef.current.stroke();

    setIsDrawing(true);
    console.log("handleMouseDown called", offsetX, offsetY);
    event.preventDefault();
  };

  // Function to handle mouse up event on the canvas
  const handleMouseUp = () => {
    linesContextRef.current.closePath();
    console.log("handleMouse UP CALLed");

    setIsDrawing(false);
  };

  // Function to handle mouse move event on the canvas
  const handleMouseMove = (event) => {
    if (!isDrawing) {
      return;
    }
    const canvas = linesCanvasRef.current;

    const rect = canvas.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const offsetY = event.clientY - rect.top;
    console.log("DRAWING COLOR", drawingColor);

    if (drawingColor == "Erase") {
      linesContextRef.current.globalCompositeOperation = "destination-out";
    } else {
      linesContextRef.current.globalCompositeOperation = "source-over";
    }
    linesContextRef.current.lineTo(offsetX, offsetY);
    linesContextRef.current.strokeStyle = drawingColor;
    linesContextRef.current.lineWidth = 3;
    linesContextRef.current.lineCap = "round";
    linesContextRef.current.stroke();
    // event.preventDefault();
    console.log("DRAWING CALLed", linesContextRef.current.stroke());

    // const canvas = canvasRef.current;
    // const ctx = canvas.getContext("2d");

    // const rect = canvas.getBoundingClientRect();
    // const x = event.clientX - rect.left;
    // const y = event.clientY - rect.top;

    // ctx.fillStyle = drawingColor;
    // ctx.beginPath();
    // ctx.arc(x, y, 5, 0, 2 * Math.PI);
    // ctx.fill();
  };

  // Function to save the image with lines
  const handleDownload = () => {
    const canvas = canvasRef.current;
    const linesCanvas = linesCanvasRef.current;
    const mergedCanvas = document.createElement("canvas");
    const mergedCtx = mergedCanvas.getContext("2d");

    mergedCanvas.width = canvas.width;
    mergedCanvas.height = canvas.height;

    // Draw the image on the merged canvas
    mergedCtx.drawImage(canvas, 0, 0);

    // Draw the lines on the merged canvas
    mergedCtx.drawImage(linesCanvas, 0, 0);

    // setSelectedImage(null);
    // console.log(editedPhotos, "UPLOADED After save");
    // Save the merged canvas as an image
    const downloadLink = document.createElement("a");
    downloadLink.href = mergedCanvas.toDataURL();
    downloadLink.download = "image_with_lines.png";

    downloadLink.click();
  };
  // Function to save the image with lines
  const saveImage = async () => {
    const canvas = canvasRef.current;
    const linesCanvas = linesCanvasRef.current;
    const mergedCanvas = document.createElement("canvas");
    const mergedCtx = mergedCanvas.getContext("2d");

    mergedCanvas.width = canvas.width;
    mergedCanvas.height = canvas.height;

    // Draw the image on the merged canvas
    mergedCtx.drawImage(canvas, 0, 0);

    // Draw the lines on the merged canvas
    mergedCtx.drawImage(linesCanvas, 0, 0);

    // Convert the merged canvas to a data URL
    const mergedDataURL = mergedCanvas.toDataURL();

    // Create a new File object from the data URL asynchronously
    const newFile = await (async () => {
      const response = await fetch(mergedDataURL);
      const blob = await response.blob();
      return new File([blob], "myfile.png", { type: "image/png" }); // Replace 'myfile.png' with the desired file name
    })();

    // Replace the edited image with the new edited image in the uploadedPhotos array
    const selectedPos = uploadedPhotos.findIndex(
      (photo) => photo.preview === selectedImage.preview
    );
    uploadedPhotos[selectedPos].file = newFile;
    uploadedPhotos[selectedPos].preview = mergedDataURL;
    setUploadedPhotos([...uploadedPhotos]);
    setSelectedImage(null);
  };

  useEffect(() => {
    const totalPrice = repairs.reduce(
      (total, repair) => total + parseFloat(repair.totalprice),
      0
    );
    setTotalPrice(totalPrice);
  }, [repairs]);

  const fetchData = async () => {
    
    try {
      const response = await axios.post(BASELINE + 'get/invoice');
      console.log(response.data);
      setgetrepairs(response.data);
      const totalPages = Math.ceil(response.data.length / maxRowsPerPage);
      setTotalPages(totalPages);
    } catch (error) {
      console.error('Error retrieving data:', error);
    } 
  };

  useEffect(() => {
      fetchData();
  }, []);

  const handleRemove = async (id) => {
    const confirmRemoval = window.confirm(
      "Are you sure you want to remove this invoice?"
    );

    if (confirmRemoval) {
      try {
          await axios.post(BASELINE + `remove/invoice/${id}`);
        alert('Invoice removed');
        fetchData(); // Call the fetchData function to fetch updated data
      } catch (error) {
        console.error("Error removing data:", error);
        // Handle error (e.g., show an error message)
      }
    }
  };

  // Function to handle touch start event on the canvas
  const handleTouchStart = (event) => {
    const canvas = linesCanvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const offsetX = event.touches[0].clientX - rect.left;
    const offsetY = event.touches[0].clientY - rect.top;
    linesContextRef.current.beginPath();
    linesContextRef.current.moveTo(offsetX, offsetY);
    setIsDrawing(true);
    event.preventDefault();
  };

  // Function to handle touch end event on the canvas
  const handleTouchEnd = () => {
    linesContextRef.current.closePath();
    setIsDrawing(false);
  };

  // Function to handle touch move event on the canvas
  const handleTouchMove = (event) => {
    if (!isDrawing) {
      return;
    }
    const canvas = linesCanvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const offsetX = event.touches[0].clientX - rect.left;
    const offsetY = event.touches[0].clientY - rect.top;

    if (drawingColor === "Erase") {
      linesContextRef.current.globalCompositeOperation = "destination-out";
    } else {
      linesContextRef.current.globalCompositeOperation = "source-over";
    }

    linesContextRef.current.lineTo(offsetX, offsetY);
    linesContextRef.current.strokeStyle = drawingColor;
    linesContextRef.current.lineWidth = 3;
    linesContextRef.current.lineCap = "round";
    linesContextRef.current.stroke();
    // event.preventDefault();
  };

  // Effect to initialize the canvas and set up event listeners
  useEffect(() => {
    const canvas = canvasRef.current;
    const linesCanvas = linesCanvasRef.current;
    if (!canvas || !linesCanvas) return;

    const ctx = canvas.getContext("2d");
    const linesCtx = linesCanvas.getContext("2d");

    console.log(selectedImage);
    const img = new Image();
    img.src = selectedImage.preview;
    img.onload = () => {
      const devicePixelRatio = window.devicePixelRatio || 1;

      // // Update the canvas dimensions based on the device pixel ratio
      canvas.width = canvas.clientWidth * devicePixelRatio;
      canvas.height = canvas.clientHeight * devicePixelRatio;
      linesCanvas.width = linesCanvas.clientWidth * devicePixelRatio;
      linesCanvas.height = linesCanvas.clientHeight * devicePixelRatio;

      // // Scale the context by the device pixel ratio
      // ctx.scale(devicePixelRatio, devicePixelRatio);
      // linesCtx.scale(devicePixelRatio, devicePixelRatio);

      // // Draw the image on the canvas
      // ctx.drawImage(image, 0, 0, selectedImage.width, selectedImage.height, 0, 0, canvas.width, canvas.height);

      // ==============================================

      // get the scale
      var scale = Math.min(canvas.width / img.width, canvas.height / img.height);
      // get the top left position of the image
      var x = (canvas.width / 2) - (img.width / 2) * scale;
      var y = (canvas.height / 2) - (img.height / 2) * scale;
      ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
      ctx.scale(devicePixelRatio, devicePixelRatio);
      linesCtx.scale(devicePixelRatio, devicePixelRatio);



    };

    contextRef.current = ctx;
    linesContextRef.current = linesCtx;

  }, [selectedImage]);


  const getFileName = (invoice) => {
    // Format the date from the "issueDate" property (assuming it's in ISO format)
    const formattedDate = new Date(invoice.issueDate).toISOString().split('T')[0];
    // Combine invoice ID, customer name, and date to form the file name
    const fileName = `Invoice_${invoice.id}_${invoice.clientName}_${formattedDate}.pdf`;
    return fileName;
  };

  return (
    <div>
      {isModalOpen && (
        <div className="fixed inset-0" style={{ zIndex: "60" }}>
          <div className="bg-white p-4 rounded-xl w-screen h-screen">
            {currentSlide === 1 && (
              <>
                {/* Slide 1 - Customer Information */}
                <h2 className="text-lg font-bold mb-4">Customer Information</h2>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    value={Fname}
                    onChange={(e) => setFname(e.target.value)}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#A14A4A] focus:border-[#A14A4A] sm:text-sm"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    value={Lname}
                    onChange={(e) => setLname(e.target.value)}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#A14A4A] focus:border-[#A14A4A] sm:text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#A14A4A] focus:border-[#A14A4A] sm:text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    value={Pnum}
                    onChange={(e) => setPnum(e.target.value)}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#A14A4A] focus:border-[#A14A4A] sm:text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Address
                  </label>
                  <input
                    type="address"
                    name="address"
                    value={address}
                    onChange={(e) => setaddress(e.target.value)}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#A14A4A] focus:border-[#A14A4A] sm:text-sm"
                  />
                </div>

                <form>
                  {/* Add your customer information form fields */}
                  <button
                    type="button"
                    className="mt-4 bg-[#A14A4A] text-white py-2 px-4 rounded"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="mt-4 bg-[#A14A4A] text-white py-2 px-4 rounded"
                    onClick={goToNextSlide}
                  >
                    Next
                  </button>
                </form>
              </>
            )}

            {currentSlide === 2 && (
              <>
                <div
                  className="min-w-[280px] items-center bg-[#A14A4A] text-white p-2 rounded"
                  style={{
                    position: "fixed",
                    top: "40px",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <form onSubmit={handleSubmit} className="text-xs">
                    <button
                      type="button"
                      className="px-4 py-2 rounded"
                      onClick={() => setIsModal2Open(true)}
                    >
                      +
                    </button>
                    <button
                      type="button"
                      className="px-4 py-2 rounded"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="px-4 py-2 rounded"
                      onClick={goToPreviousSlide}
                    >
                      Previous
                    </button>
                    <button type="submit" className="px-4 py-2 rounded">
                      Submit
                    </button>
                    {/*
                    <PDFDownloadLink
                      document={
                        <InvoicePdf
                          repairs={repairs}
                          billedTo={{ Fname, Lname, Email, Pnum, address }}
                          attachedFiles={uploadedPhotos}
                        />
                      }
                      fileName="invoice.pdf"
                    >
                      {({ blob, url, loading, error }) => (
                        <button
                          type="button"
                          disabled={loading}
                          className="btn btn-primary mx-2"
                        >
                          {loading ? "Loading" : "Preview"}
                        </button>
                      )}
                      </PDFDownloadLink> */}
                    {/* <button type="button" className="px-4 py-2 rounded" onClick={convertToPDF}>
                            Download
                          </button> */} 
                  </form>
                </div>

                {/* Table */}
                <div className="p-8 h-[790px] invoice-parent-container max-h-full overflow-y-auto mt-[25px]">
                  <div className="text-2xl font-bold mb-4">
                    Toprealtyco. INVOICE
                  </div>

                  {/* Customer Information */}
                  <div className="border-b-2 pb-4 mb-4">
                    <div className="text-lg font-bold mb-2">Billed To:</div>
                    <div className="mb-2">
                      <div className="">
                        Full Name: {Fname} {Lname}
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="">Email: {Email}</div>
                      {/* Insert customer's email here */}
                    </div>
                    <div className="mb-2">
                      <div className="">Phone Number: {Pnum}</div>
                      {/* Insert customer's phone number here */}
                    </div>
                    <div className="mb-2">
                      <div className="">Address:{address}</div>
                      {/* Insert customer's address here */}
                    </div>
                  </div>

                  {/* Invoice Details */}
                  <div className="flex justify-between mb-4">
                    <div>
                      Invoice Number: {/* Insert invoice number here */}
                    </div>
                    <div>Create Date: {/* Insert create date here */}</div>
                  </div>

                  {/* Repair List */}
                  <table className="max-w-full mb-4 text-sm overflow-x-auto">
                    <thead>
                      <tr>
                        <th
                          className="py-2 bg-[#d9b6b6]"
                          style={{ width: "65%", minWidth: "100px" }}
                        >
                          Title
                        </th>
                        <th
                          className="py-2 bg-[#d9b6b6]"
                          style={{ width: "20%", minWidth: "80px" }}
                        >
                          Note
                        </th>
                        <th
                          className="py-2 bg-[#d9b6b6]"
                          style={{ width: "5%", minWidth: "50px" }}
                        >
                          Hrs (Hours)
                        </th>
                        <th
                          className="py-2 bg-[#d9b6b6]"
                          style={{ width: "5%", minWidth: "50px" }}
                        >
                          Price
                        </th>
                        <th
                          className="py-2 bg-[#d9b6b6]"
                          style={{ width: "5%", minWidth: "50px" }}
                        >
                          Total Price
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {repairs.map((repair, index) => (
                        <tr key={index}>
                          <td className="px-4 py-2 text-center">
                            {repair.houseLocation}
                          </td>
                          <td className="px-4 py-2 text-center">
                            {repair.note}
                          </td>
                          <td className="px-4 py-2 text-center">
                            {repair.hours}
                          </td>
                          <td className="px-4 py-2 text-center">
                            {repair.price}
                          </td>
                          <td className="px-4 py-2 text-center">
                            {repair.totalprice}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {/* Attach Photos */}
                  <div className="mb-4">
                    <div className="font-bold">Attach Photos:</div>
                    <input
                      type="file"
                      name="photos"
                      multiple
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#A14A4A] focus:border-[#A14A4A] sm:text-sm"
                      onChange={handlePhotoUpload}
                    />
                    {/* Display uploaded photos */}
                    {uploadedPhotos.length > 0 && (
                      <div
                        className="mt-2 flex flex-wrap"
                        style={{ zIndex: -1 }}
                      >
                        {uploadedPhotos.map((photo, index) => (
                          <div className="relative w-1/3 mb-2" key={index}>
                            <img
                              src={photo.preview}
                              alt={`Preview ${index + 1}`}
                              className="w-100 h-auto rounded p-[8px]"
                            />
                            <button
                              type="button"
                              className="absolute top-0 right-0 p-4 text-[#A14A4A] font-bold"
                              onClick={() => removeUploadedPhoto(index)}
                            >
                              X
                            </button>
                            <button
                              type="button"
                              className="absolute top-0  bg-[#A14A4A] text-white py-2 px-4 rounded"
                              onClick={() => handleSelectImage(index)}
                            >
                              Edit
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="mb-4">
                      {/* Selected image for drawing */}
                      {selectedImage && (
                        <div className="">
                          <div className="fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-black bg-opacity-50">
                            (
                            <canvas
                              ref={canvasRef}
                              className="absolute top-0 left-0 "
                              style={{
                                // backgroundImage: `url(${selectedImage.preview})`,

                                border: "2px solid red",
                                width: "100%",
                                height: "100%",
                              }}
                            ></canvas>
                            <canvas
                              ref={linesCanvasRef}
                              className="absolute top-0 left-0 h-screen w-screen"
                              style={{
                                border: "2px solid blue",
                                width: "100%",
                                height: "100%",
                              }}
                              onMouseDown={handleMouseDown}
                              onMouseUp={handleMouseUp}
                              onMouseMove={handleMouseMove}
                              onMouseLeave={handleMouseUp}
                              onTouchStart={handleTouchStart}
                              onTouchEnd={handleTouchEnd}
                              onTouchMove={handleTouchMove}
                            ></canvas>
                            )
                          </div>

                          {/* Color buttons */}
                          <div className="fixed bottom-0 left-0 w-full bg-black bg-opacity-50">
                            <div className="flex justify-center py-4">
                              {/* The top row with five buttons */}
                              <div className="grid grid-cols-5 gap-4">
                                <button
                                  type="button"
                                  style={{ border: "1px solid black" }}
                                  className={`mx-2 p-2 rounded-full ${drawingColor === "black"
                                    ? "bg-black"
                                    : "bg-gray-200"
                                    }`}
                                  onClick={() => handleSelectColor("black")}
                                >
                                  <svg
                                    className="w-[18px] h-[18px]"
                                    preserveAspectRatio="xMidYMid meet"
                                    version="1.0"
                                    viewBox="0 0 256.000000 256.000000"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g
                                      fill={
                                        drawingColor == "black"
                                          ? "white"
                                          : "black"
                                      }
                                      stroke="none"
                                      transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
                                    >
                                      <path d="M2016 2465 c-22 -8 -53 -24 -70 -36 -35 -25 -175 -171 -345 -359 -320 -352 -690 -719 -1088 -1078 l-190 -170 -41 -105 c-66 -169 -203 -587 -200 -610 2 -13 11 -23 24 -25 21 -3 316 96 559 188 162 62 138 42 400 335 319 356 648 680 1090 1071 283 252 325 307 325 428 -1 68 -31 115 -156 237 -91 89 -128 119 -159 128 -53 14 -101 13 -149 -4z m117 -159 c46 -19 173 -154 181 -193 4 -17 2 -50 -4 -72 -12 -47 -56 -90 -420 -422 -390 -355 -503 -467 -1021 -1009 l-187 -195 -78 -29 c-44 -16 -84 -31 -91 -33 -6 -3 -14 6 -18 18 -11 32 -81 105 -116 119 -36 15 -35 23 12 135 28 67 38 79 251 280 351 332 706 689 954 960 331 362 392 423 439 440 51 18 59 18 98 1z" />
                                    </g>
                                  </svg>
                                </button>
                                <button
                                  type="button"
                                  className={`mx-2 p-2 rounded-full ${drawingColor === "blue"
                                    ? "bg-blue-500"
                                    : "bg-gray-200"
                                    }`}
                                  style={{ border: "1px solid blue" }}
                                  onClick={() => handleSelectColor("blue")}
                                >
                                  <svg
                                    className="w-[18px] h-[18px]"
                                    preserveAspectRatio="xMidYMid meet"
                                    version="1.0"
                                    viewBox="0 0 256.000000 256.000000"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g
                                      fill={
                                        drawingColor == "blue"
                                          ? "white"
                                          : "blue"
                                      }
                                      stroke="none"
                                      transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
                                    >
                                      <path d="M2016 2465 c-22 -8 -53 -24 -70 -36 -35 -25 -175 -171 -345 -359 -320 -352 -690 -719 -1088 -1078 l-190 -170 -41 -105 c-66 -169 -203 -587 -200 -610 2 -13 11 -23 24 -25 21 -3 316 96 559 188 162 62 138 42 400 335 319 356 648 680 1090 1071 283 252 325 307 325 428 -1 68 -31 115 -156 237 -91 89 -128 119 -159 128 -53 14 -101 13 -149 -4z m117 -159 c46 -19 173 -154 181 -193 4 -17 2 -50 -4 -72 -12 -47 -56 -90 -420 -422 -390 -355 -503 -467 -1021 -1009 l-187 -195 -78 -29 c-44 -16 -84 -31 -91 -33 -6 -3 -14 6 -18 18 -11 32 -81 105 -116 119 -36 15 -35 23 12 135 28 67 38 79 251 280 351 332 706 689 954 960 331 362 392 423 439 440 51 18 59 18 98 1z" />
                                    </g>
                                  </svg>
                                </button>
                                <button
                                  type="button"
                                  style={{ border: "1px solid red" }}
                                  className={`mx-2 p-2 rounded-full ${drawingColor === "red"
                                    ? "bg-red-500 text-white"
                                    : "bg-gray-200"
                                    }`}
                                  onClick={() => handleSelectColor("red")}
                                >
                                  <svg
                                    className="w-[18px] h-[18px]"
                                    preserveAspectRatio="xMidYMid meet"
                                    version="1.0"
                                    viewBox="0 0 256.000000 256.000000"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g
                                      fill={
                                        drawingColor == "red" ? "white" : "red"
                                      }
                                      stroke="none"
                                      transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
                                    >
                                      <path d="M2016 2465 c-22 -8 -53 -24 -70 -36 -35 -25 -175 -171 -345 -359 -320 -352 -690 -719 -1088 -1078 l-190 -170 -41 -105 c-66 -169 -203 -587 -200 -610 2 -13 11 -23 24 -25 21 -3 316 96 559 188 162 62 138 42 400 335 319 356 648 680 1090 1071 283 252 325 307 325 428 -1 68 -31 115 -156 237 -91 89 -128 119 -159 128 -53 14 -101 13 -149 -4z m117 -159 c46 -19 173 -154 181 -193 4 -17 2 -50 -4 -72 -12 -47 -56 -90 -420 -422 -390 -355 -503 -467 -1021 -1009 l-187 -195 -78 -29 c-44 -16 -84 -31 -91 -33 -6 -3 -14 6 -18 18 -11 32 -81 105 -116 119 -36 15 -35 23 12 135 28 67 38 79 251 280 351 332 706 689 954 960 331 362 392 423 439 440 51 18 59 18 98 1z" />
                                    </g>
                                  </svg>
                                </button>
                                <button
                                  type="button"
                                  style={{ border: "1px solid white" }}
                                  className={`mx-2 p-2 rounded-full text-white ${drawingColor === "blue"
                                    ? "bg-white-500"
                                    : "bg-gray-200"
                                    }`}
                                  onClick={() => handleSelectColor("Erase")}
                                >
                                  <BentoIcon />
                                </button>
                                <button
                                  type="button"
                                  style={{ border: "1px solid white" }}
                                  className={`mx-2 p-2 rounded-full text-white ${"bg-black text-white"}`}
                                  onClick={() => handleDownload()}
                                >
                                  <CloudDownloadIcon />
                                </button>
                              </div>
                            </div>

                            <div className="flex justify-center py-4">
                              {/* The bottom row with two buttons */}
                              <div className="grid grid-cols-2 gap-4">
                                <button
                                  type="button"
                                  className="bg-blue-500 text-white py-2 px-4 rounded"
                                  onClick={() => saveImage()}
                                >
                                  Save
                                </button>
                                <button
                                  type="button"
                                  className="bg-[#A14A4A] text-white py-2 px-4 rounded"
                                  onClick={() => {
                                    setSelectedImage(null);
                                  }}
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Total Price */}
                  <div className="text-xl font-bold mb-4">
                    Total Price: ${totalPrice}
                  </div>

                  {/* Thank You Message */}
                  <div className="mt-4 flex flex-row space-x-8">
                    <div>
                      <div className="text-lg font-bold">T H A N K Y O U !</div>
                      <div className="">Payment Information</div>
                      <div>Make it payable to Promax LLC</div>
                    </div>
                    <div>
                      <div className="font-bold right-0">PromaxPDX</div>
                      <div>15160 NW Laidlaw Rd Suite 116,</div>
                      <div>Portland, OR 97229</div>
                    </div>
                  </div>
                </div>
                {/* Second Modal */}
                {isModal2Open && (
                  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-4 rounded-xl w-screen h-screen">
                      <h2
                        className="text-lg font-bold mb-4"
                        style={{ zIndex: "70" }}
                      >
                        Add Repair
                      </h2>
                      <div className="">
                        <div className="mb-4">
                          <label className="block text-sm font-medium text-gray-700">
                            House Location
                          </label>
                          <select
                            name="houseLocation"
                            value={houseLocation}
                            onChange={handleHouseLocationChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#A14A4A] focus:border-[#A14A4A] sm:text-sm"
                          >
                            <option value="">Select House Location</option>
                            {houseFloorLocations.map((location, index) => (
                              <option value={location} key={index}>
                                {location}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="mb-4">
                          <label className="block text-sm font-medium text-gray-700">
                            Note
                          </label>
                          <textarea
                            rows="4"
                            value={note}
                            onChange={handleNoteChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#A14A4A] focus:border-[#A14A4A] sm:text-sm"
                          ></textarea>
                        </div>

                        <div className="mb-4">
                          <label className="block text-sm font-medium text-gray-700">
                            Hours of Work
                          </label>
                          <input
                            type="text"
                            value={Hours}
                            onChange={handleHourChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#A14A4A] focus:border-[#A14A4A] sm:text-sm"
                            pattern="\d*"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>

                        <div className="mb-4">
                          <label className="block text-sm font-medium text-gray-700">
                            Price ($)
                          </label>
                          <input
                            type="text"
                            value={price}
                            onChange={handlePriceChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#A14A4A] focus:border-[#A14A4A] sm:text-sm"
                            pattern="\d*"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>

                        <div className="mb-4">
                          <label className="block text-sm font-medium text-gray-700">
                            Total Price
                          </label>
                          <input
                            type="text"
                            value={totalPrice2}
                            onChange={handleTotalPriceChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#A14A4A] focus:border-[#A14A4A] sm:text-sm"
                            placeholder={`${Hours * price}`}
                            pattern="\d*"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>

                      <button
                        type="button"
                        className="mt-4 bg-[#A14A4A] text-white py-2 px-4 rounded"
                        onClick={() => setIsModal2Open(false)}
                      >
                        Close
                      </button>

                      <button
                        type="button"
                        className="mt-4 bg-[#A14A4A] text-white py-2 px-4 rounded m-[3px]"
                        onClick={() => handleSave()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}

      <button
        className="mt-[20px] bg-[#A14A4A] text-white p-2 rounded-xl"
        onClick={addRepair}
      >
        Add Repair
      </button>

      <div className="mt-4 mb-2">
        <input
          type="text"
          placeholder="Search by name..."
          className="px-4 py-2 border border-gray-300 rounded-md w-full"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {Array.from({ length: totalPages }, (_, pageIndex) => (
        <div className="table-responsive overflow-x-auto max-w-full">
          <table className="mt-4 w-full border border-gray-300 border-collapse">
            <thead>
              <tr className="bg-[#A14A4A] text-white">
                <th className="px-4 py-2 md:w-1/4 sm:w-1/2 text-center">
                  Customer
                </th>
                <th className="px-4 py-2 md:w-1/4 sm:w-1/2 text-center">
                  Date
                </th>
                <th className="px-4 py-2 md:w-1/4 sm:w-1/2 text-center">
                  Total Price
                </th>
                <th className="px-4 py-2 md:w-1/4 sm:w-1/2 text-center">
                  Email
                </th>
                <th className="px-4 py-2 md:w-1/4 sm:w-1/2 text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {getrepairs
                .filter((repair) =>
                  repair.clientName.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .slice(pageIndex * maxRowsPerPage, (pageIndex + 1) * maxRowsPerPage)
                .map((repair, index) => (
                  <tr className="border-b border-gray-300" key={index}>


                    <td
                      className="px-4 py-2 md:w-1/4 sm:w-1/2 text-center"
                      style={{ textAlign: "center", cursor: "pointer" }}
                      onClick={() => handleCopyName(repair.clientName)}
                    >
                      {namecopied ? "Copied!" : repair.clientName}
                    </td>
                    <td
                      className="px-4 py-2 md:w-1/4 sm:w-1/2 text-center"
                      style={{ textAlign: "center" }}
                    >
                      {new Date(repair.issueDate).toLocaleDateString()}
                    </td>
                    <td
                      className="px-4 py-2 md:w-1/4 sm:w-1/2 text-center"
                      style={{ textAlign: "center" }}
                    >
                      ${repair.totalPrice}
                    </td>
                    <td
                      className="px-4 py-2 md:w-1/4 sm:w-1/2 text-center"
                      style={{ textAlign: "center", cursor: "pointer" }}
                      onClick={() => handleCopyEmail(repair.email)}
                    >
                      {copied ? "Copied!" : repair.email}
                    </td>
                    <td
                      className="px-4 py-2 md:w-1/4 sm:w-1/2 text-center"
                      style={{ textAlign: "center" }}
                    >
                      <button
                        className="mr-2"
                        onClick={() => activatePDFDownloadLink(repair)}
                      >
                        View
                      </button>
                      <button onClick={() => handleRemove(repair.id)}>
                        Remove
                      </button>
                      {activeRepairId === repair.id && (
                        <PDFDownloadLink
                          document={
                            <View_InvoicePdf
                              repairs={JSON.parse(repair.repairRequest)}
                              billedTo={{
                                id: repair.id,
                                date: repair.issueDate,
                                clientName: repair.clientName,
                                Email: repair.email,
                                Pnum: repair.phoneNumber,
                                address: repair.address,
                              }}
                              attachedFiles={repair.photos}
                              totalPrice={repair.totalPrice} // Add totalPrice prop
                            />
                          }
                          fileName={getFileName(repair)} // Call the getFileName function to generate the file name
                        >
                          {({ blob, url, loading, error }) => (
                            <button type="button" disabled={loading} className="btn btn-primary mx-2">
                              {loading ? 'Loading' : 'Download'}
                            </button>
                          )}
                        </PDFDownloadLink>

                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ))
      }
    </div >
  );
}

export default Repair_dashboard;
