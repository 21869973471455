import { useLocation, Route, Switch } from 'react-router-dom'

import Home from "./pages/home/index";
import Blog from "./pages/blog/index";
import Testimonial from "./pages/testimonial/index";
import AboutUs from "./pages/aboutus/index";
import Footer from "./layout/Footer";
import AdminLogin from "./pages/admin/AdminLogin";
import Dashboard from "./pages/admin/dashboard";
import Landlord from "./pages/landlords/Index";
import Residents from "./pages/residents/Index";
import BlogPost from "./pages/blog/post";
import Livechat from "./ChatwootComponent";

import PrivateRoute from "./PrivateRoute";
import { useEffect } from "react";

const App = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Top Realty Co';
  }, [pathname]);

  return (
    <>
    <Livechat />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route component={Blog} path="/blog" />
        <Route component={BlogPost} path="/article/:id" />

        <Route component={Testimonial} path="/testimonial" />
        <Route component={AboutUs} path="/aboutus" />
        <Route component={AdminLogin} path="/admin/login" />

        <Route component={Landlord} path="/landlord" />
        <Route component={Residents} path="/residents" />

        <PrivateRoute path="/admin/dashboard" component={Dashboard} />
      </Switch>
      <Footer />
    </>
  );
};

export default App;
