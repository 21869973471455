const About = () => {
  return (
    <div className="wrapper mt-14 sm:mt-[130px] mb-20 py-8 sm:py-20 bg-primary">
      <div className="contain flex-col justify-start items-start gap-6 sm:gap-10">
        <Row
          title="Collecting Rents"
          desc="We understand that timely rent collection is essential to the success of your investment. That's why we have made it our priority to collect rent from tenants every month and deposit the funds into your account promptly. With our expeditious payment process, you can rest assured that your rental income is always received on time."
        />
        <Row
          title="Routine Inspections"
          desc="To ensure that your property is always in top condition, we conduct regular inspections at our property management company. These inspections help us to identify any maintenance or repair needs and ensure that the property is being well-maintained by tenants. Our team provides detailed reports with pictures to show landlords the condition of their property and to ensure that tenants are adhering to the terms of their lease and taking good care of the property."
        />
        <Row
          title="Comprehensive Accounting"
          desc="We understand that accurate accounting and reporting are essential for landlords to manage their investment effectively. That's why we offer an all-inclusive accounting and reporting system, which is backed by a state-of-the-art platform. Our system provides landlords with itemized statements and tax documents, giving them a comprehensive overview of their property's financial performance. With our transparent and user-friendly system, you can have peace of mind knowing that your investment is in good hands."
        />
        <Row
          title="Coordinating Maintenance"
          desc="We handle all aspects of property maintenance to ensure that your property remains in top condition. We have a vast network of reliable contractors, vendors, and in-house staff to provide you with high-quality maintenance services at preferred pricing. Our experienced team manages every aspect of maintenance, from routine inspections to emergency repairs, so you can rest assured that your property is in good hands. We are committed to providing exceptional service and ensuring that your property is always well-maintained."
        />
      </div>
    </div>
  );
};

export default About;

const Row = ({ title, desc }) => {
  return (
    <div className="grid items-start grid-cols-1 lg:grid-cols-2 w-full gap-3 lg:gap-10 ">
      <h3 className="text-[25px] sm:text-[35px] lg:text-[48px] pt-6 leading-[1.2] font-normal font-manrope text-white">
        {title}
      </h3>
      <p className="text-white textSmall">{desc}</p>
    </div>
  );
};
