import React from "react";

const Contact = () => {
  return (
    <div className="max-w-[1600px] px-5 xl:px-12 flex gap-6 mt-[100px] items-center flex-col xl:flex-row">
      {/*
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2793.732628454283!2d-122.83524968443942!3d45.55570387910217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54950f76feb29b71%3A0x96e03963a000dd1a!2sMMT%20Prep!5e0!3m2!1sko!2sus!4v1683500047046!5m2!1sko!2sus"
        width="600"
        height="550"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        className="w-full rounded-xl"
      ></iframe>
  */}
<div className="flex flex-col items-center justify-center w-full gap-6 bg-silver max-w-none py-12 xl:py-24 rounded-xl text-center">
  <h2 className="testimonial-heading text-2xl sm:text-3xl">
    Contact Information
  </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full px-6">
          <div className="bg-white p-4 rounded-sm font-medium flex items-center gap-4">
            <div className="bg-brownGr p-3 rounded-md">
              <img src="/phone.png" alt="phone" className="w-6" />
            </div>
            971-278-9421
          </div>
          <div className="bg-white p-4 rounded-sm font-medium flex items-center gap-4">
            <div className="bg-brownGr p-3 rounded-md">
              <img src="/check.png" alt="check" className="w-6" />
            </div>
            sean@toprealtyco.com
          </div>
          <div className="bg-white p-4 rounded-sm font-medium flex items-center gap-4">
            <div className="bg-brownGr p-3 rounded-md">
              <img src="/msg.png" alt="message" className="w-6" />
            </div>
            9:00am ~ 10:00pm
          </div>
          <div className="bg-white p-4 rounded-sm font-medium flex items-center gap-4">
            <div className="bg-brownGr p-3 rounded-md">
              <img src="/notebook.png" alt="notebook" className="w-6" />
            </div>
            15160 NW Laidlaw Rd Suite 116
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
