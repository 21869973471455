import { useState } from "react";

const Services = () => {
  const [activeIndex, setActiveIndex] = useState(0); // initialize active index to 0

  const handleServiceBoxClick = (index) => {
    setActiveIndex(index); // set the clicked service box index as active index
  };

  const getImgName = (index, active) => {
    if (active) {
      return `service${index + 1}.png`; // if active, return normal image name
    } else {
      return `d_service${index + 1}.png`; // if not active, return disabled image name
    }
  };


  return (
    <div className="wrapper py-[40px] sm:py-[80px] px-5 bg-mahroon">
      <div className="flex w-full max-w-[1375px] flex-col justify-start items-center gap-10 sm:gap-14">
        <h2 className="title text-center text-white">Discover Our Services</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 w-full gap-6">

          <div onClick={() => handleServiceBoxClick(0)} >
            <ServiceBox
              img={getImgName(0, activeIndex === 0)} // dynamically set image name
              title="5% Monthly Rental Charge"
              desc="We keep up-to-date with the latest property management laws and provide you with fully screened and qualified tenants. "
              active={activeIndex === 0} // check if active index is 0
              onClick={() => handleServiceBoxClick(0)} // pass the index to the click handler
            />
          </div>

          <div onClick={() => handleServiceBoxClick(1)} >
            <ServiceBox
              img={getImgName(1, activeIndex === 1)} // dynamically set image name
              title="99.9% eviction-free tenant record"
              desc="Our property management stays current with laws and provides fully screened, qualified tenants."
              active={activeIndex === 1} // check if active index is 1
            />
          </div>

          <div onClick={() => handleServiceBoxClick(2)} >
            <ServiceBox
              img={getImgName(2, activeIndex === 2)} // dynamically set image name
              title="11-Month Tenant Guarantee"
              desc="If a tenant we place leaves within 11 months, we will find a new tenant for free."
              active={activeIndex === 2} // check if active index is 2
            />
          </div>

          <div onClick={() => handleServiceBoxClick(3)} >
            <ServiceBox
              img={getImgName(3, activeIndex === 3)} // dynamically set image name
              title="Flexible monthly contract."
              desc="Our goal is for you to choose to stay with us because you love our service, not because you're tied to a management contract."
              active={activeIndex === 3} // check if active index is 3
            />
          </div>

        </div>
      </div>
    </div>
  );
};

export default Services;

const ServiceBox = ({ img, desc, title, active }) => {
  return (
    <div
      className={`flex w-full justify-start items-center text-center sm:text-left sm:items-start flex-col gap-3 rounded-[20px] p-5 ${active ? "bg-white" : "bg-transparent"
        }`}
    >
      <div className="flex items-center">
        <img src={img} className="h-[60px] object-contain" alt="" />
        <h3
          className={`ml-[10px] text-xl sm:text-2xl font-bold ${active ? "text-[#363A3D]" : "text-white"
            }`}
        >
          {title}
        </h3>
      </div>
      
      <p
        className={`text-base font-normal ${active ? "text-gray" : "text-white"
          }`}
      >
        {desc}
      </p>
    </div>
  );
};
