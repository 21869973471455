import React from 'react';
import logo from './pdf-logo.png';
import { Font, Image, Page, Document, StyleSheet, Text, View } from '@react-pdf/renderer';
import { BASELINE } from '../../../util/index';

export const View_InvoicePdf = ({ repairs, billedTo, attachedFiles, totalPrice }) => {
    const renderAttachedFiles = () => {
        if (Array.isArray(attachedFiles)) {
            return attachedFiles.map((file, index) => (
                <View key={index.toString()} style={{ width: '50%', padding: 10 }}>
                    <Image src={`${BASELINE}${file}`} style={{ width: '100%' }} />
                </View>
            ));
        } else {
            return null;
        }
    };

    return (
        <Document>
            <Page>
                <Image src={logo} style={styles.backgroundImage} />
                <View style={styles.container}>
                    {/* Header */}
                    <View style={styles.headerSection}>
                        <Text style={styles.headerText2}>Toprealtyco. INVOICE</Text>
                        <Text style={styles.billingToText}>Billed To:</Text>
                        <Text style={styles.customerText2}>Full Name: {billedTo.clientName}</Text>
                        <Text style={styles.customerText2}>Email: {billedTo.Email}</Text>
                        <Text style={styles.customerText2}>Phone Number: {billedTo.Pnum}</Text>
                        <Text style={styles.customerText2}>Address: {billedTo.address}</Text>
                    </View>
                    <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <View style={styles.separatorLine} />
                    </View>

                    {/* Table */}
                    <View>
                        {/* Table Header */}
                        <View style={styles.invoiceInfo}>
                            <Text style={styles.customerText}>Invoice Number: {billedTo.id}</Text>
                            <Text style={styles.customerText}>Create Date: {billedTo.date}</Text>
                        </View>
                        <View style={{ marginHorizontal: 20, marginVertical: 10, paddingHorizontal: 10 }}>
                            <View style={styles.tableHeader}>
                                <Text style={{ flex: 2.5, textAlign: 'center', ...styles.billingToText2 }}>Title</Text>
                                <Text style={{ flex: 1, textAlign: 'center', ...styles.billingToText2 }}>Note</Text>
                                <Text style={{ flex: 1, textAlign: 'center', ...styles.billingToText2 }}>Hrs (Hours)</Text>
                                <Text style={{ flex: 1, textAlign: 'center', ...styles.billingToText2 }}>Price</Text>
                                <Text style={{ flex: 1, textAlign: 'center', ...styles.billingToText2 }}>Total Price</Text>
                            </View>
                            <View>
                                {repairs && repairs.map((repair, index) => (
                                    <View key={index} style={{ display: 'flex', flexDirection: 'row', marginVertical: 5, paddingVertical: 5 }}>
                                        <Text style={{ flex: 2.5, textAlign: 'center', fontSize: 8 }}>{repair.houseLocation}</Text>
                                        <Text style={{ flex: 1, textAlign: 'center', fontSize: 8, wordWrap: 'break-word' }}>{repair.note}</Text>
                                        <Text style={{ flex: 1, textAlign: 'center', fontSize: 8 }}>{repair.hours}</Text>
                                        <Text style={{ flex: 1, textAlign: 'center', fontSize: 8 }}>{repair.price}</Text>
                                        <Text style={{ flex: 1, textAlign: 'center', fontSize: 8 }}>{repair.totalprice}</Text>
                                    </View>
                                ))}
                            </View>

                        </View>

                        <View style={{ marginTop: 10, marginHorizontal: 20, paddingHorizontal: 10 }}>
                            <Text style={styles.headerText3}>Total Price: ${totalPrice}</Text>
                        </View>
                        <View style={{ marginVertical: 10, flexDirection: 'row', marginHorizontal: 20, paddingHorizontal: 10 }}>
                            <View style={{ width: '40%' }}>
                                <Text style={{ ...styles.headerText, letterSpacing: 2 }}>THANK YOU !</Text>
                                <Text style={{ ...styles.customerText }}>Payment Information</Text>
                                <Text style={{ ...styles.customerText }}>Make it payable to Promax LLC</Text>
                            </View>
                            <View style={{ left: '140px' }}>
                                <Text style={styles.headerText}>PromaxPDX</Text>
                                <Text style={styles.customerText}>15160 NW Laidlaw Rd Suite 116,</Text>
                                <Text style={styles.customerText}>Portland, OR 97229</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
            {Array.isArray(attachedFiles) && attachedFiles.length > 0 && (
                <Page>
                    <Image src={logo} style={styles.backgroundImage} />
                    <View style={styles.headerSection}>
                        <Text style={styles.headerText2}>Toprealtyco. Images</Text>
                    </View>
                    {/* Attached Files */}
                    <View style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                        {renderAttachedFiles()}
                    </View>
                    <View style={{ marginVertical: 10, flexDirection: 'row', marginHorizontal: 20, paddingHorizontal: 10 }}>
                        <View style={{ width: '40%' }}>
                            <Text style={{ ...styles.headerText, letterSpacing: 2 }}>THANK YOU !</Text>
                            <Text style={{ ...styles.customerText }}>Payment Information</Text>
                            <Text style={{ ...styles.customerText }}>Make it payable to Promax LLC</Text>
                        </View>
                        <View style={{ left: '140px' }}>
                            <Text style={styles.headerText}>PromaxPDX</Text>
                            <Text style={styles.customerText}>15160 NW Laidlaw Rd Suite 116,</Text>
                            <Text style={styles.customerText}>Portland, OR 97229</Text>
                        </View>
                    </View>
                </Page>
            )}
        </Document>
    );
};

const styles = StyleSheet.create({
    backgroundImage: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        height: '60%',
        opacity: 0.1,
    },
    container: {
        flexGrow: 1,
    },
    headerSection: {
        marginVertical: 10,
        marginHorizontal: 20,
        paddingHorizontal: 20,
        paddingVertical: 10,
    },
    headerText: {
        fontSize: 16,
        fontWeight: 'bold',
        paddingVertical: 3,
    },
    headerText2: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#703333',
        paddingVertical: 5,
    },
    headerText3: {
        fontSize: 14,
        fontWeight: 'bold',
        paddingVertical: 3,
        textAlign: 'right',
    },
    billingToText: {
        fontSize: 10,
        fontWeight: 'bold',
        paddingVertical: 3,
    },
    billingToText2: {
        fontSize: 10,
        fontWeight: 'bold',
        paddingVertical: 3,
        color: 'white',
    },
    customerText: {
        fontSize: 12,
        paddingVertical: 3,
    },
    customerText2: {
        fontSize: 8,
        paddingVertical: 3,
    },
    separatorLine: {
        width: '90%',
        height: 2,
        backgroundColor: '#000',
    },
    invoiceInfo: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginHorizontal: 20,
        marginVertical: 5,
        paddingHorizontal: 10,
    },
    tableHeader: {
        paddingHorizontal: 5,
        paddingVertical: 10,
        backgroundColor: '#A14A4A',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 8,
    },
});
