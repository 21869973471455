const Section = ({ img, title, desc, reverse, classes }) => {
  return (
    <div className={`wrapper ${classes}`}>
      <div
        className={`contain ${
          reverse ? "flex-col lg:flex-row-reverse" : "flex-col lg:flex-row"
        } justify-between items-center lg:gap-10`}
      >
        <img src={img} className="w-full max-w-[450px] object-contain" alt="" />
        <div className="flex lg:max-w-[480px] justify-start items-start flex-col gap-2">
          <h3 className="text-black sm:text-left text-center w-full text-[30px] sm:text-[36px] font-normal font-manrope">
            {title}
          </h3>
          <div className="flex justify-start items-start gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="#AC1D1D"
              className="w-9 min-w-[36px] h-9"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                clipRule="evenodd"
              />
            </svg>

            <p className="textSmall text-black">{desc}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section;
